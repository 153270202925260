import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 200px;
  .module {
    border-radius: 12px;
    padding: 40px 75px 24px 30px;
    background-image: url("/images/home/bg_process.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .head {
      .desc-section {
        margin-top: 5px;
        span {
          color: #fff !important;
        }
      }
    }
    .content {
      display: flex;
      gap: 20px;
      margin-top: 24px;
      .item {
        border-radius: 4px;
        padding: 86px 50px;
        background: #ffffff99;
        backdrop-filter: blur(200px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        overflow: hidden;
        min-width: 172px;
        width: 172px;
        height: 266px;
        transition: all 0.2s linear;
        cursor: pointer;
        .item-content {
          display: flex;
          align-items: center;
          gap: 48px;
          .idx {
            min-width: 72px;
            height: auto;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #0d0d0f;
            color: #0d0d0f;
            font-size: 40px;
            line-height: 123%;
            font-weight: 600;
          }
          .info {
            display: flex;
            align-items: center;
            gap: 48px;
            color: #0d0d0f;
            display: none;
            opacity: 0;
            transition: all 0.4s linear;

            .label {
              font-size: 60px;
              font-weight: 600;
              line-height: 123%;
              white-space: nowrap;
            }
            .desc {
              max-width: 451px;
            }
          }
        }
        .arrow {
          width: 109px;
          display: none;
        }

        &.active {
          width: 100%;
          background: #ebebeb;
          .item-content {
            .idx {
              background: #0d0d0f;
              color: #fff;
            }
            .info {
              opacity: 1;
              display: flex;
            }
          }
          .arrow {
            display: block;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1536px) {
    padding-top: 120px;
    .module {
      padding: 30px 30px 24px 30px;
      .content {
        gap: 16px;
        .item {
          min-width: 140px;
          width: 140px;
          padding: 66px 40px;
          height: 220px;

          .item-content {
            gap: 30px;

            .idx {
              min-width: 60px;
              font-size: 36px;
            }
            .info {
              gap: 30px;
              .label {
                font-size: 44px;
              }
            }
          }
          .arrow {
            width: 79px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 100px;
    .module {
      .content {
        gap: 16px;
        .item {
          min-width: 110px;
          width: 110px;
          padding: 66px 30px;
          height: 180px;

          .item-content {
            gap: 24px;

            .idx {
              min-width: 50px;
              font-size: 30px;
            }
            .info {
              gap: 24px;
              .label {
                font-size: 32px;
              }
              .desc {
                font-size: 16px;
              }
            }
          }
          .arrow {
            width: 59px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    padding-top: 100px;
    .module {
      .content {
        .item {
          min-width: 100px;
          width: 100px;
          padding: 50px 26px;
          height: 160px;

          .item-content {
            gap: 24px;

            .idx {
              min-width: 50px;
              font-size: 30px;
            }
            .info {
              gap: 12px;
              flex-direction: column;
              align-items: flex-start;

              .label {
                font-size: 28px;
              }
              .desc {
                font-size: 16px;
              }
            }
          }
          .arrow {
            width: 50px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    .module {
      padding: 30px 16px 24px 16px;
      .content {
        flex-direction: column;
        .item {
          min-width: auto;
          width: 100%;
          padding: 26px 15px;
          height: auto;
          background: #ebebeb;

          .item-content {
            gap: 24px;
            margin: 0 auto;

            .idx {
              min-width: 50px;
              font-size: 30px;
              background: #0d0d0f;
              color: #fff;
              display: none;
            }
            .info {
              text-align: center;
              gap: 12px;
              flex-direction: column;
              align-items: center;
              display: flex;
              opacity: 1;
              justify-content: center;

              .label {
                font-size: 24px;
              }
              .desc {
                font-size: 16px;
              }
            }
          }
          .arrow {
            width: 59px;
            display: none !important;
          }
        }
      }
    }
  }
`;
