import React from "react";
import { Styles } from "./styles";

import BannerSection from "./Banner";
import AboutSection from "./AboutUs";
import ServicesSection from "./Services";
import OurPartnersSection from "./OurPartners";
import OurTeamSection from "./OurTeam";
import ArticlesSection from "./Articles";
import ProcessSection from "./Process";
import ProblemSection from "./Problems";

const HomePage = () => {
  return (
    <Styles>
      <BannerSection />
      <AboutSection />
      <ProblemSection />
      <ServicesSection />
      <ProcessSection />
      <OurPartnersSection />
      <OurTeamSection />
      <ArticlesSection />
    </Styles>
  );
};

export default HomePage;
