import React from "react";
import { Styles } from "./styles";
import TitleSection from "component/Title";
import Marquee from "react-fast-marquee";

const OurPartnersSection = () => {
  return (
    <Styles>
      <div className="container">
        <div data-aos="fade-up">
          <TitleSection
            title="OUR PARTNERS"
            index="05"
            desc={
              <>
                We pride ourselves on our professionalism and firmly say no to
                any abruptness! We are committed to partnering with{" "}
                <span>long-term projects.</span>
              </>
            }
          />
        </div>
      </div>
      <div className="content" data-aos="fade-up">
        <Marquee className="data-list">
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
        </Marquee>
        <Marquee className="data-list" direction="right">
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
          <img src="/images/home/icon_bnb.png" alt="" />
        </Marquee>
      </div>
    </Styles>
  );
};

export default OurPartnersSection;
