import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 200px;
  .content {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    .service-item {
      background: #3e383980;
      backdrop-filter: blur(20px);
      padding: 24px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s linear;
      .icon {
        width: 54px;
        height: auto;
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        &::after {
          content: "";
          position: absolute;
          width: 0px;
          height: 5px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 0 80px 15px #ff2447;
          z-index: -1;
        }
      }
      .item-content {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        .title {
          font-size: 32px;
          line-height: 123%;
          font-weight: 600;
        }
        .desc {
        }
      }

      &:hover {
        transform: translateY(-15px);
      }
    }
  }

  @media only screen and (max-width: 1536px) {
    padding-top: 120px;
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 100px;
    .content {
      gap: 20px;
      .service-item {
        .icon {
          width: 48px;
          svg {
            transform: scale(0.8);
          }
        }
        .item-content {
          margin-top: 20px;
          gap: 16px;
          .title {
            font-size: 24px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    .content {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: 480px) {
    .content {
      grid-template-columns: repeat(1, 1fr);
      .service-item {
        text-align: center;
        padding: 20px 16px;
        .icon {
          margin: 0 auto;
        }
      }
    }
  }
`;
