import React, { ReactNode } from "react";
import { Styles } from "./styles";
import Header from "../Header";
import ScrollToTop from "component/ScrollToTop";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Styles>
      <Header />
      <div className="children">{children}</div>
      <ScrollToTop />
    </Styles>
  );
};

export default Layout;
