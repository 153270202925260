import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "Clash-Grotesk";
  src: url("/fonts/ClashGrotesk-Variable.ttf");
}

html{
  scroll-behavior: smooth;
}


    * {
        font-family: "Clash-Grotesk";
        box-sizing: border-box;
        ::-webkit-scrollbar-thumb {
            background: #240C10; 
        }
        button{
          border: none;
          outline: none;
        }
        p{
          margin-block-start: 0;
          margin-block-end: 0;
        }
        ul{
            margin-block-start: 0px;
            margin-block-end: 0px;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 0px;
            li{
              list-style: none;
            }
        }
        a{
          text-decoration: none;
        }
    }
  body {
    background: #0A0B0C;
    padding: 0;
    margin: 0;
    color: #fff;
    font-family: "Clash-Grotesk";
    font-size: 18px;
    font-weight: 400;
    line-height: 123%;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
    
    img {
      height: auto;
      max-width: 100%;
    }

    
  }

  .container{
    width: 100%;
    max-width: 1647px;
    padding: 0 15px;
    margin:  0 auto;
    @media only screen and (max-width: 1530px) {
      max-width: 1330px;
    }
    @media only screen and (max-width: 1280px) {
      max-width: 1130px;
    }
  }
 `;

export default GlobalStyle;
