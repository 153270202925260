import React from "react";
import { Styles } from "./styles";
import TitleSection from "component/Title";

const AboutSection = () => {
  return (
    <Styles id="about-us">
      <div className="container">
        <div className="head" data-aos="fade-up">
          <TitleSection
            title="About Us"
            index="01"
            desc={
              <>
                We bridge the gap between <span>Web3 projects</span> and{" "}
                <span>Influencers</span>, unleashing their imagination and
                execution in the world of Web3 marketing
              </>
            }
          />
        </div>
        <div className="content" data-aos="fade-up" data-aos-delay="100">
          {data?.map((el, idx) => {
            return (
              <div key={idx} className="item">
                <div className="label">{el.label}</div>
                <div className="value">{el.value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};

export default AboutSection;

const data = [
  {
    label: "Network",
    value: "100+ KOLs",
  },
  {
    label: "Accumulative Global Audience",
    value: "100M+",
  },
  {
    label: "Accumulative Global Impressions",
    value: "500+/month",
  },
];
