import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { StylesHeader } from "./styles";
import { ReactComponent as IconX } from "assets/icons/x.svg";
import { ReactComponent as IconTele } from "assets/icons/telegram.svg";
import useCheckScroll from "hooks/useCheckScroll";
import { HiMiniBars3 } from "react-icons/hi2";
import { CgClose } from "react-icons/cg";
import { url } from "inspector";

const Header = () => {
  const [toglleNav, setToggleNav] = useState(false);
  const isScroll = useCheckScroll();
  const [currentHash, setCurrentHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setCurrentHash(window.location.hash);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  console.log(currentHash);

  return (
    <>
      <StylesHeader className={classNames({ scroll: isScroll })}>
        <div className="container">
          <div className="module">
            <Link to="#" className="logo">
              <img src="/images/logo.png" alt="" className="logo_des" />
              <img src="/images/logo_mobi.png" alt="" className="logo_mobi" />
            </Link>
            <div className={`nav ${classNames({ navShow: toglleNav })}`}>
              <div className="close-nav" onClick={() => setToggleNav(false)}>
                <CgClose size={24} />
              </div>
              <div className="nav-list">
                {menu?.map((el, idx) => {
                  return (
                    <a
                      href={el.url}
                      className={`nav-item ${classNames({
                        active: currentHash === el?.url,
                      })}`}
                      onClick={() => setToggleNav(false)}
                      key={idx}
                    >
                      {el.label}
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="action">
              <div className="social">
                <Link to={"https://twitter.com/0xtypinglab"} target="__blank">
                  <IconX />
                </Link>
                <Link to="/">
                  <IconTele />
                </Link>
              </div>
              <button className="btn_contact">Contact</button>
              <div
                className="toglle-nav"
                onClick={() => setToggleNav(!toglleNav)}
              >
                <HiMiniBars3 />
              </div>
            </div>
          </div>
        </div>
      </StylesHeader>
    </>
  );
};

export default Header;

const menu = [
  {
    url: "#about-us",
    label: "About Us",
  },
  {
    url: "#problems",
    label: "Problems",
  },
  {
    url: "#services",
    label: "Services",
  },
  {
    url: "#process",
    label: "Process",
  },
  {
    url: "#our-team",
    label: "Our Team",
  },
];
