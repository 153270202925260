import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import GlobalStyle from "../src/styles/Global";
import { BrowserRouter as Router } from "react-router-dom";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <GlobalStyle />
      <App />
    </Router>
  </React.StrictMode>
);
