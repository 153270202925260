import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 200px;
  .head {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    .title-team {
      font-size: 52px;
      font-weight: 700;
      line-height: 123%;
    }
  }
  .content {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px 30px;
    justify-content: center;
    .item {
      width: 100%;
      max-width: 400px;
      border-radius: 4px;
      border: 1px solid #373132;
      background: linear-gradient(
        180deg,
        rgba(62, 56, 57, 0.5) 0%,
        rgba(62, 56, 57, 0.09) 100%
      );
      padding: 42px 20px 30px 20px;
      cursor: pointer;
      position: relative;

      transition: all 0.2s linear;
      .item-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .avt {
          width: 230px;
          height: auto;
          aspect-ratio: 1/1;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
        .name {
          font-size: 28px;
          font-weight: 700;
          line-height: 123%;
        }
      }
      .info {
        margin-top: 20px;
        padding: 16px 0;
        border-top: 1px solid #3731325e;
        border-bottom: 1px solid #3731325e;
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-height: 156px;
        .info-item {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            min-width: 16px;
          }
          p {
            font-size: 16px;
            line-height: 123%;
            span {
              font-weight: 600;
            }
          }
        }
      }
      .social {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        height: 24px;
        svg {
          min-width: 24px;
          height: auto;
        }
      }
      .founder {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 8px 16px;
        border-radius: 4px;
        background: #ff2447;
      }

      &:hover {
        transform: translateY(-15px);
      }
    }
  }
  @media only screen and (max-width: 1536px) {
    padding-top: 120px;
    .head {
      gap: 24px;
      .title-team {
        font-size: 42px;
      }
    }
    .content {
      margin-top: 60px;
      gap: 40px 24px;
      .item {
        max-width: 360px;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 100px;
    .head {
      gap: 24px;
      .title-team {
        font-size: 36px;
      }
    }
    .content {
      margin-top: 60px;
      gap: 40px 20px;
      .item {
        max-width: 320px;
        padding: 30px 16px 20px 16px;
        .item-head {
          .avt {
            width: 180px;
          }
          .name {
            font-size: 20px;
          }
        }
        .info {
          margin-top: 16px;
          .info-item {
            p {
              font-size: 14px;
            }
          }
        }
        .social {
          margin-top: 16px;
        }
        .founder {
          font-size: 14px;
          padding: 6px 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 80px;
    .head {
      .title-team {
        font-size: 24px;
      }
    }
  }
`;
