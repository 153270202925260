import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import TitleSection from "component/Title";
import moment from "moment";
import { Link } from "react-router-dom";

const ArticlesSection = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@0xmoongate"
        );

        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Styles>
      <div className="container">
        <div data-aos="fade-up">
          {" "}
          <TitleSection
            title="Articles"
            index="07"
            desc={
              <>
                <span>Lastest news</span> from TypingLab
              </>
            }
          />
        </div>
        <div className="content">
          {data?.items?.slice(0, 6)?.map((el: any, idx: number) => {
            return (
              <Link
                to={el?.link}
                target="_blank"
                key={idx}
                className="item"
                data-aos="fade-up"
                data-aos-delay={50 * (idx + 1)}
              >
                <div className="item-img">
                  <img
                    src={
                      (el["description"] as any)
                        .toString()
                        .match(/<img[^>]+src="([^">]+)"/)[1]
                    }
                    alt=""
                  />
                </div>
                <div className="item-content">
                  <p className="title">{el?.title}</p>
                  <p className="time">{formatDateTime(el?.pubDate)}</p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};

export default ArticlesSection;

const formatDateTime = (
  date: string | number | Date,
  formatStr = "HH:mm yyyy/MM/DD"
) => (date ? moment(new Date(date)).format(formatStr) : date);
