import React from "react";
import { StylesBanner } from "./styles";
import { Link } from "react-router-dom";
import { ReactComponent as IconArrow } from "assets/icons/arrow.svg";
import { ReactComponent as IconArrowDown } from "assets/icons/arrow_down.svg";

import Typewriter from "typewriter-effect";

const BannerSection = () => {
  return (
    <StylesBanner>
      <div className="container">
        <div className="module">
          <div className="head">
            <p className="title" data-aos="fade-up">
              <Typewriter
                options={{
                  strings: [`TYPING <strong>LAB</strong>`],
                  autoStart: true,
                  loop: true,
                }}
              />
            </p>
            <p className="desc" data-aos="fade-up" data-aos-delay="200">
              A SUPER MCN ON BLOCKCHAIN
            </p>
          </div>
          <div className="content" data-aos="fade-up" data-aos-delay="400">
            <p className="text">
              The Web3 Native Growth Agency Growing your Favourite Projects
            </p>
            <div className="btn_action">
              <Link to={"https://www.google.com/"} target="_blank">
                <button className="btn_booking">
                  Booking{" "}
                  <span>
                    <IconArrow />
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <img src="/images/home/cricle_bn.png" alt="" className="base" />
          <img src="/images/home/star-img.png" alt="" className="star star-1" />
          <img src="/images/home/star-img.png" alt="" className="star star-2" />
        </div>
        <div className="module-block">
          <div className="info" data-aos="fade-right" data-aos-delay="200">
            <div className="item">
              <div className="item-content">
                <p className="title">
                  About Blocktitans:{" "}
                  <span>the most successful MCN incubated by TypingLabs</span>
                </p>
              </div>
            </div>
            <span className="hr" />
            <div className="item">
              <div className="item-content">
                <p className="title">
                  Network: <span>10+ KOLs</span>
                </p>
              </div>
            </div>
            <span className="hr" />
            <div className="item">
              <div className="item-content">
                <p className="title">
                  Accumulative Audience: <span>200k+</span>
                </p>
              </div>
            </div>
          </div>
          <Link to={"https://blocktitans.notion.site/aboutus"} target="_blank">
            <button className="btn" data-aos="fade-left" data-aos-delay="200">
              Visit Blocktitans{" "}
              <span>
                <IconArrow />
              </span>
            </button>
          </Link>
        </div>
      </div>
      <div className="action">
        <IconArrowDown />
        <p>Scroll down</p>
      </div>
    </StylesBanner>
  );
};

export default BannerSection;
