import classNames from "classnames";
import useCheckScroll from "hooks/useCheckScroll";
import React from "react";
import styled from "styled-components";

const ScrollToTop = () => {
  const isScroll = useCheckScroll();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Styles className={classNames({ scroll: isScroll })} onClick={scrollToTop}>
      <img src="/images/home/scroll.png" alt="" />
    </Styles>
  );
};

export default ScrollToTop;

const Styles = styled.div`
  position: fixed;
  z-index: 100;
  right: 40px;
  bottom: 40px;
  cursor: pointer;
  display: none;
  opacity: 0;
  transition: all 0.2s linear;
  &.scroll {
    display: block;
    opacity: 1;
  }
  @media only screen and (max-width: 1280px) {
    right: 30px;
    bottom: 30px;
    width: 30px;
    background: #ffffff30;
    border-radius: 4;
    img {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    right: 20px;
    bottom: 30px;
    width: 24px;
    background: #ffffff30;
    border-radius: 4;
    img {
      width: 100%;
    }
  }
`;
