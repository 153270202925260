import React from "react";
import { Styles } from "./styles";
import TitleSection from "component/Title";
import { ReactComponent as IconService1 } from "assets/icons/service1.svg";
import { ReactComponent as IconService2 } from "assets/icons/service2.svg";
import { ReactComponent as IconService3 } from "assets/icons/service3.svg";
import { title } from "process";

const ServicesSection = () => {
  return (
    <Styles id="services">
      <div className="container">
        <div data-aos="fade-up">
          <TitleSection
            title="Services"
            index="03"
            desc={
              <>
                All-in-One: Marketing, Strategy, and Influencer Management{" "}
                <br /> Just <span>make a call</span> with TypingLab
              </>
            }
          />
        </div>

        <div className="content">
          {data.map((item, index) => (
            <div
              key={index}
              className="service-item"
              data-aos="fade-up"
              data-aos-delay={100 * (index + 1)}
            >
              <div className="icon">{item.icon}</div>
              <div className="item-content">
                <p className="title">{item.title}</p>
                <p className="desc">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Styles>
  );
};

export default ServicesSection;

const data = [
  {
    title: "Content",
    desc: (
      <>
        Short or long form,
        <br />
        we have it covered.
        <br />
        Yes, pepe memes and dank replies too!
      </>
    ),
    icon: <IconService1 />,
  },
  {
    title: "Growth Strategy",
    desc: (
      <>
        Scaling new heights,
        <br />
        with data-driven insights.
        <br />
        Growth is not just a goal, it's our mantra.
      </>
    ),
    icon: <IconService2 />,
  },
  {
    title: "Marketing Campaign",
    desc: (
      <>
        From micro-influencers to industry giants. We handle marketing campaigns
        with KOLs of all sizes. Choose our service, and watch your brand soar!
      </>
    ),
    icon: <IconService3 />,
  },
];
