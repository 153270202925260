import styled from "styled-components";

export const StylesBanner = styled.section`
  min-height: 100vh;
  width: 100%;
  background-image: url("/images/home/bg_banner.png");
  background-position: top center;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 30px;
  .module {
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
    z-index: 1;

    .head {
      text-align: center;
      .title {
        font-size: 100px;
        font-weight: 700;
        line-height: 123%;
        color: #0a0b0c;
        text-shadow: 0 0 4px #ff2447;
        strong {
          color: #ff2447 !important;
          text-shadow: none;
        }
        .Typewriter__cursor {
          color: #ff2447;
        }
      }
      .desc {
        font-weight: 700;
        font-size: 60px;
        line-height: 123%;
      }
    }
    .content {
      margin-top: 30px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 30px;
      .text {
        font-size: 18px;
      }
      .btn_action {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
        .btn_booking {
          background: #ff2447;
          border-radius: 4px;
          height: 48px;
          width: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          text-transform: uppercase;
          cursor: pointer;
          transition: all 0.2s linear;

          span {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
          }

          &:hover {
            background: #fff;
            color: #ff2447;
            span {
              background: #ff2447;
            }
          }
        }
      }
    }
    @keyframes identifier {
      0% {
        transform: translate(-50%, -50%) scale(1);
      }
      100% {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
    @keyframes scale {
      0% {
        transform: scale(0.5);
      }
      100% {
        transform: scale(1.2);
      }
    }
    .base {
      position: absolute;
      top: 45%;
      left: 50%;
      z-index: -1;
      width: 500px;
      height: auto;
      transform-origin: center;
      aspect-ratio: 1/1;
      animation: identifier 2s linear infinite alternate;
    }

    .star {
      position: absolute;
      z-index: -1;
      height: auto;
      aspect-ratio: 1/1;
      animation: scale 1s linear infinite alternate;

      &.star-1 {
        width: 29px;
        top: -20px;
        left: 8%;
        animation-delay: 0.2s;
      }
      &.star-2 {
        width: 49px;
        bottom: 5%;
        right: 7%;
      }
    }
  }
  .module-block {
    max-width: 1000px;
    padding: 40px;
    margin: 0 auto;
    background-image: url("/images/home/bg_problems.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    .info {
      width: 100%;
      max-width: 600px;
      .item {
        position: relative;

        .item-content {
          .title {
            font-size: 20px;
            line-height: 123%;
            font-weight: 500;
            span {
              color: #acacac;
              font-size: 18px;
            }
          }
        }

        &:nth-child(2) {
          border-top: 1px solid #4a272c;
          border-bottom: 1px solid #4a272c;
        }
      }
      .hr {
        height: 1px;
        width: 100%;
        background: #4a272c;
        margin: 16px 0;
        display: block;
      }
    }

    .btn {
      margin-top: 20px;
      background: #fff;
      border-radius: 4px;
      padding: 10.5px 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s linear;
      color: #0d0d0f;

      span {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff2b3f;
      }

      &:hover {
        background: #ff2b3f;
        color: #fff;
        span {
          background: #fff;
        }
      }
    }
  }
  .action {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1;
  }

  @media only screen and (max-width: 1536px) {
    min-height: 100vh;

    .module {
      margin-top: 80px;
      .head {
        .title {
          font-size: 90px;
        }
        .desc {
          font-size: 50px;
        }
      }
      .base {
        width: 450px;
      }
    }
    .action {
      display: none;
    }
  }
  @media only screen and (max-width: 1280px) {
    .module {
      padding-top: 80px;
      .head {
        .title {
          font-size: 70px;
        }
        .desc {
          font-size: 36px;
        }
      }
      .content {
        margin-top: 24px;
        gap: 24px;
      }
      .base {
        width: 350px;
      }
    }
    .module-block {
      padding: 30px 20px;
      margin-top: 30px;
      max-width: 720px;
      justify-content: center;
      align-items: center;
      .info {
        max-width: 480px;
        .item {
          .item-content {
            .title {
              font-size: 16px;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
      .btn {
        font-size: 16px;
        width: auto;
        height: 40px;
        padding: 6px 10px;
        gap: 8px;
        margin: 0 auto;
        span {
          width: 28px;
          height: 28px;
          svg {
            width: 20px;
          }
        }
      }
    }
    .action {
      transform: scale(0.8) translateX(-50%);
      bottom: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    .module {
      .head {
        .title {
          font-size: 50px;
        }
        .desc {
          font-size: 24px;
        }
      }
      .content {
        .text {
          font-size: 16px;
        }
      }
      .base {
        width: 300px;
      }
    }
    .action {
      transform: scale(0.8) translateX(-50%);
      bottom: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    .module {
      margin-top: 40px;
      .head {
        .title {
          font-size: 40px;
        }
        .desc {
          font-size: 20px;
          margin-top: 8px;
        }
      }
      .content {
        .text {
          font-size: 16px;
        }
        .btn_action {
          gap: 12px;

          .btn_booking,
          .btn_visit {
            font-size: 16px;
            width: auto;
            height: 40px;
            padding: 6px 10px;
            gap: 8px;
            span {
              width: 28px;
              height: 28px;
              svg {
                width: 20px;
              }
            }
          }
        }
      }
      .base {
        width: 280px;
      }
    }
    .module-block {
      padding: 20px 16px;
      max-width: 600px;
      .info {
        .item {
          display: flex;
          align-items: center;
          flex-direction: column;

          .item-content {
            .title {
              text-align: center;
            }
          }
        }
      }
    }
    .action {
      transform: scale(0.6) translateX(-50%);
      bottom: 20px;
    }
  }
`;
