import React, { useEffect } from "react";
import HomePage from "../views/HomePage";
import reportWebVitals from "../reportWebVitals";
import { Route, Routes } from "react-router-dom";
import Layout from "../component/Layout";
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
  }, []);
  useEffect(() => {
    const handleHashChange = () => {
      const { hash } = window.location;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange);

    // Gọi hàm xử lý ngay khi trang được load với hash trong URL
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  return (
    <Layout>
      <Routes>
        <Route index element={<HomePage />} />
      </Routes>
    </Layout>
  );
}

export default App;

reportWebVitals();
