import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title-section {
    display: flex;
    align-items: center;
    gap: 4px;
    .index {
      font-size: 24px;
    }
    .title {
      border-radius: 4px;
      padding: 4px 20px;
      background: #fff;
      font-size: 24px;
      line-height: 29.5px;
      color: #0d0d0f;
      font-weight: 500;
    }
  }
  .desc-section {
    font-size: 40px;
    color: #fff;
    line-height: 123%;
    span {
      font-weight: 700;
      color: #ff2447;
    }
  }
  @media only screen and (max-width: 1536px) {
    gap: 16px;

    .desc-section {
      font-size: 32px;
    }
  }
  @media only screen and (max-width: 1280px) {
    gap: 16px;
    .title-section {
      .index {
        font-size: 20px;
      }
      .title {
        font-size: 20px;
      }
    }
    .desc-section {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    .title-section {
      .index {
        font-size: 16px;
      }
      .title {
        font-size: 16px;
        padding: 4px 16px;
      }
    }
    .desc-section {
      font-size: 18px;
      text-align: center;
    }
  }
`;
