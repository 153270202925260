import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 200px;
  .content {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 30px;
    .item {
      transition: all 0.2s linear;
      .item-img {
        width: 100%;
        height: auto;
        aspect-ratio: 455/340;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.2s linear;
        }
      }
      .item-content {
        margin-top: 10px;
        .title {
          font-size: 22px;
          line-height: 123%;
          font-weight: 600;
          color: #fff;
        }
        .time {
          margin-top: 8px;
          font-size: 16px;
          color: #d6d6d6;
          line-height: 123%;
        }
      }

      &:hover {
        transform: translateY(-15px);
        .item-img {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1536px) {
    padding-top: 120px;
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 100px;
    .content {
      gap: 40px 20px;
      .item {
        .item-img {
          aspect-ratio: 455/300;
        }
        .item-content {
          .title {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    .content {
      gap: 40px 20px;
      grid-template-columns: repeat(2, 1fr);
      .item {
        .item-img {
        }
        .item-content {
          .title {
            font-size: 18px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .content {
      gap: 30px 20px;
      grid-template-columns: repeat(1, 1fr);
      .item {
        .item-img {
        }
        .item-content {
          .title {
            font-size: 18px;
          }
          .time {
            font-size: 14px;
          }
        }
      }
    }
  }
`;
