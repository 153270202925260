import React, { ReactNode } from "react";
import { Styles } from "./styles";

interface Props {
  title: string;
  index: string;
  desc?: ReactNode;
}

const TitleSection = ({ title, index, desc }: Props) => {
  return (
    <Styles>
      <p className="title-section">
        <span className="index">{index}.</span>
        <span className="title">{title}</span>
      </p>
      {!!desc && <p className="desc-section">{desc}</p>}
    </Styles>
  );
};

export default TitleSection;
