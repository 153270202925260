import styled from "styled-components";

export const StylesHeader = styled.div`
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding-top: 28px;
  transition: all 0.2s linear;
  &.scroll {
    padding-top: 0;
    background: #b8183252;
    backdrop-filter: blur(30px);
    .module {
      background: transparent;
    }
  }
  .module {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 156px;
    gap: 30px;
    border-radius: 4px;
    background: linear-gradient(180deg, #5e121e 0%, #240c10 100%);

    .logo {
      display: flex;
      align-items: center;
      .logo_mobi {
        display: none;
      }
    }
    display: flex;
    align-items: center;
    gap: 77px;
    .nav {
      .close-nav {
        display: inline-block;

        position: absolute;
        z-index: 1;
        top: 20px;
        right: 20px;
        cursor: pointer;
        display: none;
      }
      .nav-list {
        display: flex;
        align-items: center;
        gap: 40px;
        .nav-item {
          font-size: 18px;
          color: #fff;
          text-decoration: none;
          transition: all 0.2s linear;
          white-space: nowrap;
          &.active {
            color: #ff2447;
          }
          &:hover {
            color: #ff2447;
          }
        }
      }
    }
    .action {
      display: flex;
      align-items: center;
      gap: 24px;
      .social {
        display: flex;
        align-items: center;
        gap: 8px;
        a {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
      .btn_contact {
        height: 42px;
        width: 110px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        color: #0d0d0f;
        background: #fff;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s linear;

        &:hover {
          background: #ff2447;
          color: #fff;
        }
      }
    }
    .toglle-nav {
      display: none;
      max-width: 30px;
      svg {
        width: 30px;
        height: auto;
      }
    }
  }
  @media only screen and (max-width: 1536px) {
    padding-top: 20px;
    .module {
      padding: 0 20px;
    }
  }

  @media only screen and (max-width: 1280px) {
    .module {
      height: 50px;
      gap: 40px;
      .logo {
        width: 180px;
      }
      .nav {
        position: fixed;
        z-index: 100;
        width: 200px;
        height: 100vh;
        top: 0;
        left: 0;
        background: #0a0b0c;
        padding: 70px 30px 0 30px;
        transform: translateX(-100%);
        transition: all 0.3s ease-out;
        border-right: 1px solid #fff;
        .close-nav {
          display: block;
        }
        .nav-list {
          flex-direction: column;
          gap: 20px;
          align-items: flex-start;
          align-items: center;
          .nav-item {
            font-size: 18px;
          }
        }
        &.navShow {
          transform: translateX(0);
        }
      }
      .action {
        gap: 20px;
        .btn_contact {
          height: 40px;
          width: 100px;
        }
      }
      .toglle-nav {
        display: flex;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .module {
      padding: 0 15px;
      .logo {
        width: 160px;
      }
      .action {
        gap: 10px;
        .btn_contact {
          height: 36px;
          font-size: 16px;
          width: 90px;
        }
      }
      .toglle-nav {
        width: 36px;
        height: 36px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    padding-top: 0px;
    background: linear-gradient(180deg, #5e121e 0%, #240c10 100%);

    .module {
      background: transparent;
      padding: 0;
      .logo {
        .logo_des {
          display: none;
        }

        .logo_mobi {
          display: block;
          width: 36px;
        }
      }
      .nav {
        transform: translate(0);
        width: 100vw;
        overflow: hidden;
        top: 50px;
        padding-top: 0;
        height: 0;
        .nav-list {
          gap: 24px;
        }
        .close-nav {
          display: block;
        }
        &.navShow {
          padding-top: 40px;
          height: calc(100vh - 50px);
        }
      }
      .action {
        .btn_buy {
          display: none;
        }
      }
    }
  }
`;
