import styled from "styled-components";

export const Styles = styled.div`
  overflow: hidden;
  background-image: url("/images/home/bg_bottom.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  padding-bottom: 120px;
`;
