import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 200px;
  .module {
    padding: 59px 107px 59px 40px;
    background-image: url("/images/home/bg_problems.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info {
      width: 100%;
      max-width: 670px;
      .item {
        position: relative;
        padding-left: 36px;
        .idx {
          position: absolute;
          font-weight: 700;
          top: 0;
          left: 0;
        }
        .item-content {
          .title {
            font-size: 32px;
            line-height: 123%;
            font-weight: 500;
          }
          .desc {
            color: #acacac;
            margin-top: 8px;

            span {
              font-weight: 700;
            }
          }
        }

        &:nth-child(2) {
          border-top: 1px solid #4a272c;
          border-bottom: 1px solid #4a272c;
        }
      }
      .hr {
        height: 1px;
        width: 100%;
        background: #4a272c;
        margin: 32px 0;
        display: block;
      }
    }
    .action {
      padding: 114px 53px 123px 53px;
      background-image: url("/images/home/bg_action.png");
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      max-width: 750px;
      .label {
        font-size: 52px;
        line-height: 123%;
        font-weight: 700;
        text-align: center;
        position: relative;
        img {
          position: absolute;
          width: 8%;
          right: 6%;
          top: -30%;
        }

        span {
          color: #ff2447;
        }
      }
      .btn {
        margin: 0 auto;
        margin-top: 20px;
        background: #fff;
        border-radius: 4px;
        padding: 10.5px 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s linear;
        color: #0d0d0f;

        span {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ff2b3f;
        }

        &:hover {
          background: #ff2b3f;
          color: #fff;
          span {
            background: #fff;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1536px) {
    padding-top: 120px;
    .module {
      padding: 50px 60px 50px 40px;
      .info {
        max-width: 550px;
        .item {
          padding-left: 30px;
          .idx {
            font-size: 16px;
          }
          .item-content {
            .title {
              font-size: 24px;
            }
            .desc {
              font-size: 16px;
            }
          }
        }
        .hr {
          margin: 24px 0;
        }
      }
      .action {
        max-width: 550px;
        padding: 94px 43px 83px 43px;
        .label {
          font-size: 40px;
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 100px;
    .module {
      padding: 40px 30px 40px 30px;
      .info {
        max-width: 500px;
      }
      .action {
        max-width: 450px;
        padding: 74px 43px 63px 43px;
        .label {
          font-size: 32px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    .module {
      padding: 30px 15px 30px 15px;
      flex-direction: column;
      .info {
        max-width: 500px;
        .item {
          text-align: center;
          .idx {
            position: static;
          }
        }
      }
      .action {
        max-width: 300px;
        padding: 0;
        background: transparent;
        margin-top: 30px;
        .label {
          font-size: 24px;
        }
        .btn {
          font-size: 16px;
        }
      }
    }
  }
`;
