import React, { useState } from "react";
import { Styles } from "./styles";
import TitleSection from "component/Title";
import classNames from "classnames";

const ProcessSection = () => {
  const [active, setActive] = useState(1);
  return (
    <Styles id="process">
      <div className="container">
        <div className="module">
          <div className="head" data-aos="fade-up">
            <TitleSection
              title="Process"
              index="04"
              desc={
                <>
                  Supercharge Your Protocol in <span>3 Steps</span>
                </>
              }
            />
          </div>
          <div className="content">
            {data.map((el, idx) => {
              return (
                <div
                  key={idx}
                  className={`item ${classNames({
                    active: active === idx + 1,
                  })}`}
                  onClick={() => setActive(idx + 1)}
                  data-aos="fade-left"
                  data-aos-delay={100 * (idx + 1)}
                >
                  <div className="item-content">
                    <div className="idx">{idx + 1}</div>
                    <div className="info">
                      <div className="label">{el.label}</div>
                      <div className="desc">{el.desc}</div>
                    </div>
                  </div>
                  <img src="/images/home/Vector.png" alt="" className="arrow" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default ProcessSection;

const data = [
  {
    label: "Step 1",
    desc: "Discovery Call: In a quick, insightful call, we understand your business needs and goals.",
  },
  {
    label: "Step 2",
    desc: "Join Our Elite Circle: We onboard you to our network, crafting a unique strategy for your business",
  },
  {
    label: "Step 3",
    desc: "Join Our Elite Circle: We onboard you to our network, crafting a unique strategy for your business",
  },
];
