import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 100px;
  .content {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 4px;
    border: 1px solid #373132;
    padding: 30px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 11px;
      .value {
        font-size: 40px;
        line-height: 123%;
        font-weight: 700;
      }
      &:nth-child(2) {
        border-left: 1px solid #373132;
        border-right: 1px solid #373132;
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    .content {
      padding: 20px;
      .item {
        .value {
          font-size: 32px;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .content {
      .item {
        padding: 0 8px;
        text-align: center;
        .value {
          font-size: 24px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 60px;
    .content {
      display: flex;
      flex-direction: column;
      .item {
        text-align: center;

        .value {
          font-size: 20px;
        }
        &:nth-child(1) {
          padding-bottom: 20px;
        }
        &:nth-child(3) {
          padding-top: 20px;
        }
        &:nth-child(2) {
          border-left: none;
          border-right: none;
          border-top: 1px solid #373132;
          border-bottom: 1px solid #373132;
          padding: 20px 0;
        }
      }
    }
  }
`;
