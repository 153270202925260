import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 200px;
  .content {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    .data-list {
      cursor: pointer;
      img {
        margin: 0 40px;
      }
    }
  }

  @media only screen and (max-width: 1536px) {
    padding-top: 120px;
    .content {
      margin-top: 40px;
      gap: 16px;
      .data-list {
        img {
          width: 200px;
          margin: 0 30px;
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 100px;
    .content {
      margin-top: 40px;
      gap: 16px;
      .data-list {
        img {
          width: 180px;
          margin: 0 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    .content {
      gap: 12px;
      .data-list {
        img {
          width: 160px;
          margin: 0 16px;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .content {
      gap: 8px;
      margin-top: 30px;

      .data-list {
        .rfm-child {
          height: 34px;
        }
        img {
          width: 120px;
          margin: 0 10px;
        }
      }
    }
  }
`;
